@mixin aspect-ratio($x, $y) {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($y / $x) * 100%;
  }

  > .inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

@mixin font-sizing($min, $max, $scale: 0.4vw) {
  font-size: $min;

  @media (min-width: $min-font-scaling-width) {
    font-size: calc(#{$min} + #{$scale});
  }

  @media (min-width: $max-content-width) {
    font-size: $max;
  }
}
