.c-hero {
  @include aspect-ratio(125, 98);

  overflow: hidden;

  img,
  source {
    width: 100%;
  }

  @supports (object-fit: cover) {
    overflow: unset;

    img,
    source {
      height: 100%;
      object-fit: cover;
      overflow-y: unset;
    }
  }

  @media (min-width: $max-content-width) {
    @include aspect-ratio(1366, 389);
  }
}
