.c-box {
  border: 2px solid $color-teal;
  box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.13);
  padding: 1.5rem 1.875rem;

  .c-box__title {
    font-size: 2.0625rem;
    line-height: 1.36;
  }
}
