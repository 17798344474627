*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  overflow-x: hidden;
}

html,
button,
input {
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-variant-ligatures: no-common-ligatures;
}

html {
  font-size: 100%;
}

body,
h1,
h2,
h3,
h4,
p {
  margin: 0;
}

body {
  @include font-sizing(1.0625rem, 1.25rem);

  background-color: $color-bg-main;
  color: $color-text;
  line-height: 1.58;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;

  > * {
    flex: 1;
  }
}

h1,
h2 {
  font-family: 'AGBook', 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.36;
}

h1 {
  @include font-sizing(2rem, 3.625rem);
}

h2 {
  @include font-sizing(2rem, 3.25rem);
}

h3 {
  @include font-sizing(1.25rem, 1.4375rem);

  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: normal;
}

ul {
  margin: 0;
  // This is the result of experimentation to try and get the ul disc to appear
  // flush left with surrounding content. The default ul padding is 40px
  // which is far too inset.
  padding-left: 1.6rem;
}

h1,
h2,
li,
p,
.u-text-width {
  max-width: $max-text-width;
}

ul li::marker {
  color: $color-disc;
}

a {
  color: $color-links;

  &:visited {
    color: $color-links-visited;
  }
}

figure {
  border: 2px solid $color-teal;
  margin: 0;

  img {
    display: block;
    max-width: 100%;
  }
}

figcaption {
  @include font-sizing(0.75rem, 1.063rem);

  border-top: 2px solid $color-teal;
  padding: 1em;
}

blockquote {
  align-items: flex-start;
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
  quotes: '\201C''\201D''\2018''\2019';

  > * {
    flex: 1;
  }

  footer {
    font-weight: 700;
    margin-top: 1rem;
  }

  p {
    font-style: italic;
  }

  &:before {
    color: $color-brand-purple;
    content: open-quote;
    font-family: 'AGBook', 'DM Sans', Helvetica, Arial, sans-serif;
    font-size: 6.6875rem;
    line-height: 1;
    margin-right: 1rem;
  }
}

@media (min-width: $max-content-width) {
  figure,
  figcaption {
    border-width: 4px;
  }
}
