.c-toc {
  list-style-type: disc;

  a {
    @include font-sizing(1.25rem, 1.4375rem);
  }
}

.c-back-to-top,
.c-toc {
  a {
    display: block;
    font-weight: 700;
  }

  a::after {
    content: '>>';
    display: inline-block;
    margin-left: 0.5rem;
  }
}
