.u-copy-style {
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: normal;
}

.u-copy-style,
.u-larger-text {
  @include font-sizing(1.25rem, 1.4375rem);
}

.u-larger-text {
  line-height: 1.75;
}

.u-hidden {
  display: none;
}

.u-mt-lg {
  margin-top: $spacing-lg;

  @media (min-width: $max-content-width) {
    margin-top: $spacing-lg-wide;
  }
}

.u-brand-disc {
  li::marker {
    color: $color-brand-purple;
  }
}

.u-unstyled-list {
  list-style-type: none;
  padding-left: 0;

  li + li {
    margin-top: 0.5rem;
  }
}
