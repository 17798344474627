.l-stacked {
  & > * + * {
    margin-top: $spacing;
  }

  & > .l-stacked__exception {
    margin-top: 0;
  }

  &--lg > * + *,
  & > .l-stacked__lg {
    margin-top: $spacing-lg;
  }
}

.l-center {
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-content-width;
  padding-left: $spacing;
  padding-right: $spacing;
}

.l-full-width {
  margin-left: -9999rem;
  margin-right: -9999rem;
  padding-left: 9999rem;
  padding-right: 9999rem;
}

.l-sidebarred {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$spacing;

  > * {
    margin-left: $spacing;
    margin-top: $spacing-lg;
  }

  &__sidebar {
    // Current sidebar is approx. 2 columns of a 9 column grid
    flex-basis: calc(#{$max-content-width} / 9 * 2.6);
    flex-grow: 1;
  }

  &__content {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 50%;
  }
}

@media (min-width: $max-content-width) {
  .l-stacked--lg > * + *,
  .l-stacked > .l-stacked__lg {
    margin-top: $spacing-lg-wide;
  }

  .l-sidebarred > * {
    margin-top: $spacing-lg-wide;
  }
}
