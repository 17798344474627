.c-helpline {
  background-color: $color-teal-lightest;
  font-size: 0.875rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  > * {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  p {
    max-width: none;
  }

  .c-helpline__button {
    background-color: #fff;
    border: 2px solid $color-teal;
    color: $color-links;
    cursor: pointer;
    flex-basis: 100%;
    font-size: 1rem;
    margin-top: 1rem;
    padding: 0.4rem 1rem;
    text-decoration: none;
  }

  @media (min-width: $max-content-width) {
    font-size: 1.25rem;

    .c-helpline__button {
      flex-basis: auto;
      font-size: 1.0625rem;
      margin-top: 0;
    }
  }
}
