.c-photo {
  @include aspect-ratio(322, 285);

  margin: 0;
  overflow: hidden;

  img,
  source {
    display: block;
    width: 100%;
  }

  @supports (object-fit: cover) {
    overflow: unset;

    img,
    source {
      height: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: $max-content-width) {
    @include aspect-ratio(962, 416);
  }
}
