.c-page-header {
  background-color: $color-brand-purple;
  flex-grow: 0;

  > * {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
      align-items: center;
      display: flex;
    }

    > * + * {
      margin-left: 1rem;
    }

    a {
      color: $color-bg-main;
    }
  }
}

.c-nav-btn {
  background-color: transparent;
  border: 2px solid #fff;
  color: $color-bg-main;
  cursor: pointer;
  font-weight: 500;
  margin-left: auto;
  margin-right: 0;
  padding: 0.5rem 1.7rem;
}

.c-nav {
  display: flex;
  flex-wrap: wrap;
  flex: 100%;
  font-weight: 500;
  list-style-type: none;
  margin-left: -$spacing;
  margin-right: -$spacing;
  padding: 0;
  z-index: 1;

  li {
    border-top: 1px solid $color-light-purple;
    flex: 100%;
    margin: 0;
    max-width: none;
  }

  a {
    color: $color-bg-main;
    display: block;
    font-size: 1.3125rem;
    padding: 1.25rem 1.625rem;
    text-decoration: none;
  }
}

.c-nav--hidden-sm {
  display: none;
}

@media (min-width: $min-font-scaling-width) {
  .c-nav-btn {
    margin-bottom: 0;
  }
}

@media (min-width: $max-content-width) {
  .c-page-header > * {
    flex-wrap: nowrap;

    > * {
      align-items: center;
      display: flex;
    }
  }

  .c-nav-btn {
    display: none;
  }

  .c-nav {
    align-items: stretch;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: 0;
    width: auto;

    li {
      align-items: center;
      border-top: 0;
      border-left: 2px solid $color-light-purple;
      display: flex;
    }

    li:last-child {
      border-right: 2px solid $color-light-purple;
    }

    a {
      padding-left: 3.125rem;
      padding-right: 3.125rem;
    }

    .c-nav__active {
      background-color: $color-light-purple;

      a {
        color: $color-text;
      }
    }
  }
}
