.c-page-footer {
  background-color: $color-teal-lightest;
  font-size: 0.875rem;
  margin-top: $spacing-lg * 2;
  margin-bottom: 0;
  padding-top: $spacing;
  padding-bottom: $spacing;

  &__nav {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin-left: -1rem;
    padding: 0;

    > * {
      margin-left: 1rem;
    }

    a {
      color: #000;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: $max-content-width) {
    margin-top: $spacing-lg-wide * 2;
  }
}
