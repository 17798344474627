.c-skip-to-main {
  display: -moz-inline-stack;
  display: inline-block;
  margin: 0;
  top: 0;
  position: absolute;
  left: -9999em;
  background: #ffffff;
  &:focus {
    left: 0;
    z-index: 1;
  }
}
