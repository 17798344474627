@font-face {
  font-family: 'AGBook';
  src: url('fonts/AGBookRounded-Medium.otf') format('opentype');
}

$color-bg-main: #fff;
$color-text: #000;
$color-brand-purple: #9c2890;
$color-mid-purple: #e1bfde;
$color-light-purple: #f0dfee;
$color-disc: #018eaa;
$color-footer-text: #979797;
$color-links: #006175;
$color-links-visited: #76256e;
$color-teal: #80c7d4;
$color-teal-lightest: #dff1f4;

$spacing: 1.625rem;
$spacing-lg: 2.5rem;
// Larger spacing to use at wider viewport widths
$spacing-lg-wide: $spacing-lg * 1.8;

$max-content-width: 63.875rem;
$max-text-width: 39.875rem;
$min-font-scaling-width: 23.5rem;
