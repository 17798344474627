.c-breadcrumbs {
  background-color: $color-light-purple;
  font-size: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  ul {
    display: flex;
    flex: 100%;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
  }

  li + li {
    margin-left: 0.75rem;
  }

  li + li::before {
    content: url(icons/chevron.svg);
    display: inline-block;
    margin-right: 0.75rem;
  }
}

@media (min-width: $min-font-scaling-width) {
  .c-breadcrumbs {
    font-size: calc(0.75rem + 0.4vw);
  }
}

@media (min-width: $max-content-width) {
  .c-breadcrumbs {
    background-color: transparent;
    font-size: 1.0625rem;
    padding-top: 3rem;

    ul {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
}
