@import url('https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap');

@import './settings';
@import './mixins';
@import './elements';
@import './layout';
@import './components/box';
@import './components/breadcrumbs';
@import './components/dwaw-logo';
@import './components/films';
@import './components/helpline';
@import './components/hero';
@import './components/page-footer';
@import './components/page-header';
@import './components/partners';
@import './components/photo';
@import './components/publications';
@import './components/skip-to-main';
@import './components/strips';
@import './components/toc';
@import './utilities';

@import './scopes/markdown';
