.c-film-list {
  list-style-type: none;
  padding: 0;
}

.c-film {
  margin-top: $spacing-lg;
  max-width: none;

  .c-film__wrapper {
    @include aspect-ratio(16, 9);

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
