.c-dwaw-logo {
  margin-right: $spacing;
  width: 9rem; // approx. 144px

  a {
    display: block;
  }

  &--padded a {
    margin-bottom: $spacing;
    margin-top: $spacing;
  }

  img {
    width: 9rem;
  }

  @media (min-width: $min-font-scaling-width) {
    width: 14.125rem;

    img {
      width: 14.125rem;
    }
  }
}
