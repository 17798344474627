$partners-gutter: 1.375rem;

.c-partners {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  margin-left: -$partners-gutter;
  padding: 0;

  li {
    @include aspect-ratio(1, 1);

    margin-left: $partners-gutter;
    margin-top: $partners-gutter;
    width: 5.625rem;

    > a {
      align-items: center;
      border: 2px solid $color-mid-purple;
      border-radius: 50%;
      display: flex;
      overflow: hidden;
      padding: 0.75rem 1rem;
    }
  }

  img {
    max-width: 100%;
    flex: 1;
  }

  @media (min-width: $min-font-scaling-width) {
    li {
      width: 7.125rem;
    }
  }

  @media (min-width: $max-content-width) {
    margin-top: $spacing;
  }
}
