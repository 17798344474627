.c-bordered-strip {
  border-bottom: 2px solid $color-mid-purple;
  border-top: 2px solid $color-mid-purple;
  padding-bottom: $spacing-lg;
  padding-top: $spacing-lg;
}

.c-colored-strip {
  background-color: $color-light-purple;
  padding-bottom: $spacing-lg;
  padding-top: $spacing-lg;
}

@media (min-width: $max-content-width) {
  .c-bordered-strip {
    border-width: 3px;
    padding-bottom: $spacing-lg-wide;
    padding-top: $spacing-lg-wide;
  }
}
