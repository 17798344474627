$grid-gutter: $spacing * 2;
$grid-cell-width: 12.5rem; // approx. 200px

.c-publication-grid {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: -$grid-gutter;
  padding: 0;

  > * {
    flex-basis: calc(#{$grid-cell-width} - $grid-gutter);
    flex-grow: 1;
    margin-top: $grid-gutter;
    margin-left: $grid-gutter;
  }

  // The numbers we put into @supports (width: min(x, y)) are arbitrary,
  // we just need to fill them in for the check.
  @supports (display: grid) and (width: min(12.5rem, 50%)) {
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: repeat(
      auto-fill,
      minmax(min(#{$grid-cell-width}, calc(50% - #{$grid-gutter})), 1fr)
    );
    margin-left: 0;

    > * {
      margin-top: 0;
      margin-left: 0;
    }
  }
}

.c-publication {
  > * {
    max-width: 12.5rem;
  }

  .c-publication__thumbnail {
    @include aspect-ratio(25, 36);
  }

  img {
    box-shadow: -1px 2px 4px 1px rgba(0, 0, 0, 0.15);
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .c-publication__title {
    display: block;
    margin-top: $spacing;
  }
}
